import React, { useMemo } from "react";
import ArticleBody from "../../ArticleBody";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import withConfig from "../../../../utils/withConfig";
import PromotionalFooter from "../Components/PromotionalFooter/PromotionalFooter";
import EditorNotes from "../Components/EditorNotes/EditorNotes";
import Disclaimer from "../../Disclaimer/Disclaimer";
import AudioPlayer from "../../../AudioPlayer/AudioPlayer";
import ByLine from "../../ByLine/ByLine";
import ArticlesCarousel from "../../../../common/Carousel/Articles/ArticlesCarousel";
import ArticleHeader from "../../ArticleHeader";
import VideoAd from "../../../Ad/VideoAd";
import values from "../../../../common/Theme/themes/breakpoints/values";
import Breadcrumbs from "../../../../common/Breadcrumbs/Breadcrumbs";
import { screenWidthIsLowerThan } from "../../../../hooks/useMediaQuery";
import Grid from "../../../../common/Grid/Grid";
import Sidebar from "./Sidebar/Sidebar";
import { useStyles } from "./Default.style";
import { object } from "prop-types";
import RelatedNews from "../../RelatedNews/RelatedNews";
import TopAds from "../Components/TopAds/TopAds";
import Leaderboard from "../Components/Leaderboard/Leaderboard";
import StickySocialNetworkShareButtons from "../../../StickySocialNetworkShareButtons/StickySocialNetworkShareButtons";
import MostRead from "../../MostRead/MostRead";

const AUDIO_TEXT_SELECTOR = "article_audio7";

const Default = ({ props, article, sponsor }) => {
  const classes = useStyles();

  const {
    article_body,
    category_name,
    subscribe_to_content,
    is_article_preview,
    is_town,
    is_premium,
    ad_targets,
    article_disclaimer,
    disclaimer_policy_page_links,
    town_editor_email,
    section_name: section,
    can_edit,
    edit_url: article_edit_url,
    section_link,
    video_ad_content,
    breadcrumbs,
    town_info,
    id,
    section_id,
    subsection_slug,
    column_id,
    town_id,
    category_id,
    bullseye_ad_count,
  } = props || {};

  const {
    name,
    url_content: url,
    subheading,
    column_for_front_end: column,
    opinions: article_column_category,
    is_advertisement,
    promotional_footer,
    ad_free,
    disclaimer,
    submitted_via,
    has_audio_player,
    byline,
    humanized_published_at: published_at,
    humanized_updated_at: important_data_changed_at,
    get_town_admin_profile_path: town_admins_profile_page_path,
    media_content,
    current_layout_name,
  } = article;

  const isMobile = screenWidthIsLowerThan(values.sm);
  const mediumScreen = screenWidthIsLowerThan(1025);
  const smallScreen = screenWidthIsLowerThan(values.sm);
  const leaderboardProps = useMemo(() => {
    if (isMobile) {
      return {
        top: { ad_unit: "leaderboard_mobile", size: [320, 100] },
        body: { ad_unit: "leaderboard_mobile_1", size: [320, 100] },
      };
    }
    return {
      top: { ad_unit: "tap_leaderboard_001", size: [728, 90] },
      body: { ad_unit: "tap_leaderboard_002", size: [728, 90] },
    };
  }, [isMobile]);

  return (
    <Grid
      container
      justifyContent="center"
      spacing={mediumScreen ? 0 : 3}
      classes={{ root: classes.container }}
    >
      {!ad_free && (
        <Leaderboard props={{ ...leaderboardProps.top, targets: ad_targets }} />
      )}

      <Grid item xs={12} classes={{ root: classes.header }}>
        {breadcrumbs?.length > 0 && <Breadcrumbs items={breadcrumbs} />}

        <ArticleHeader
          title={name}
          subheading={subheading}
          section={section}
          section_link={section_link}
          subsection_slug={subsection_slug}
          category_name={category_name}
          is_advertisement={is_advertisement}
          can_edit={can_edit || false}
          article_edit_url={article_edit_url || ""}
          is_column_article={column ? true : false}
          sponsor={sponsor}
        />
      </Grid>

      <Grid item xs={12} className={ad_free ? classes.ad_free_top_section : ""}>
        <Grid container justifyContent="center">
          {media_content?.length > 0 && (
            <Grid item xs={12} md={mediumScreen || ad_free ? 12 : 7}>
              <ArticlesCarousel items={media_content} />
            </Grid>
          )}
          {!ad_free && (
            <TopAds
              video_ad_content={video_ad_content}
              bullseye_ad_count={bullseye_ad_count}
              ad_targets={ad_targets}
              sponsor={sponsor}
              variant="default"
              sponsorClassName={classes.sponsor_root}
            />
          )}
        </Grid>
      </Grid>

      {!ad_free && (
        <Leaderboard
          props={{ ...leaderboardProps.body, targets: ad_targets }}
        />
      )}

      <Grid item xs={12}>
        <Grid container classes={{ root: is_article_preview && classes.body_container }} justifyContent={!is_article_preview && "center"}>
          <Grid
            item
            xs={smallScreen ? 12 : 7}
            classes={{
              root: is_article_preview
                ? classes.article_preview_grid
                : classes.article_grid,
            }}
          >
            <Grid container classes={{ root: classes.body }}>
              {has_audio_player && (
                <Grid item xs={12} className={classes.audio_player_container}>
                  <AudioPlayer />
                </Grid>
              )}

              <Grid item xs={12}>
                <ByLine
                  withDefault
                  author={byline}
                  published_at={published_at}
                  updated_at={important_data_changed_at}
                  author_email={town_editor_email}
                  town_admins_profile_page_path={town_admins_profile_page_path}
                  preview={is_article_preview}
                  title={name}
                  url={url}
                />
              </Grid>

              <div>
                {!is_article_preview && (
                  <StickySocialNetworkShareButtons
                    top={window?.innerHeight / 3}
                  />
                )}
                <Grid container>
                  <Grid item xs={12}>
                    <GoogleReCaptchaProvider
                      useRecaptchaNet
                      reCaptchaKey={withConfig("RECAPTCHA_KEY")}
                      scriptProps={{
                        async: true,
                        defer: true,
                        appendTo: "body",
                      }}
                    >
                      <ArticleBody
                        text={article_body}
                        column={column}
                        isAdvertisement={is_advertisement}
                        articleColumnCategory={article_column_category}
                        isAdFree={ad_free}
                        categoryName={category_name}
                        subscribeToContent={subscribe_to_content}
                        isArticlePreview={is_article_preview}
                        isTown={is_town}
                        isPremium={is_premium}
                        adTargets={ad_targets}
                        audioTextSelector={AUDIO_TEXT_SELECTOR}
                      />
                    </GoogleReCaptchaProvider>
                  </Grid>

                  {promotional_footer && (
                    <Grid item xs={12}>
                      <PromotionalFooter
                        promotionalFooter={promotional_footer}
                      />
                    </Grid>
                  )}

                  {disclaimer && (
                    <Grid
                      item
                      xs={12}
                      className={classes.editor_notes_container}
                    >
                      <EditorNotes editorNotes={disclaimer} />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Disclaimer
                      categoryName={category_name}
                      column={column}
                      isAdvertisement={is_advertisement}
                      articleColumnCategory={article_column_category}
                      submittedVia={submitted_via}
                      disclaimerPolicyPageLinks={disclaimer_policy_page_links}
                      customDisclaimer={article_disclaimer}
                    />
                  </Grid>
                </Grid>
              </div>

              {!is_article_preview && (
                <Grid item xs={12}>
                  <MostRead
                    variant={"default"}
                    articleProps={{
                      id,
                      section_id,
                      column_id,
                      town_id,
                      category_id,
                    }}
                  />
                </Grid>
              )}

              {!is_article_preview && (
                <RelatedNews
                  articleProps={{
                    id,
                    section_id,
                    column_id,
                    town_id,
                    category_id,
                  }}
                  variant={current_layout_name}
                />
              )}
            </Grid>
          </Grid>

          {!is_article_preview && (
            <Grid
              item
              xs={smallScreen ? 12 : 5}
              classes={{ root: classes.sidebar }}
            >
              <Sidebar
                adTargets={ad_targets}
                townInfo={town_info}
                noAds={ad_free}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

Default.propTypes = {
  props: object.isRequired,
  article: object.isRequired,
  sponsor: object,
};

export default Default;
